<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <h1 class="text-primary my-1">Import Excel CRS</h1>
            <b-row>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <div>
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    BFormFile,
  },
  mounted() {
    this.getItems();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],

      excelJson: {},
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      loading: false,
      items: [],
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      fetchPurchase: [],
      listPurchases: [],
      inHeaders: [],
      colWidthFactor: null,
      tempStatusOptions: [
        { label: 'Draft', value: 'A' },
        { label: 'Pending Manager', value: 'B' },
        { label: 'Approved', value: 'C' },
        { label: 'Not Approved', value: 'D' },
        { label: 'Sent To Client', value: 'E' },
        { label: 'Win', value: 'F' },
        { label: 'Loss', value: 'P' },
        { label: 'Canceled Sale', value: 'J' },
        { label: 'Win-Closed', value: 'H' },
      ],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'quote-preview', params: { id: item[0].id } });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems(val) {
      this.loading = true;
      store
        .dispatch('settings/getAllPurchaseQuote', [])
        .then((res) => {
          let filterExcelImportTrue = _.filter(res.data.data, function(o) {
            return o.excelImport;
          });
          this.totalRows = filterExcelImportTrue.length;
          this.fetchPurchase = filterExcelImportTrue;
          this.listPurchases = filterExcelImportTrue;
          var arrayStatus = [];

          filterExcelImportTrue.forEach((element) => {
            arrayStatus.push(element.status);
          });

          //   const uniqeStatus = Array.from(new Set(arrayStatus));
          //   this.tempStatusOptions.forEach((element) => {
          //     uniqeStatus.forEach((item) => {
          //       if (element.value == item) this.statusOptions.push(element);
          //     });
          //   });
          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.inHeaders.push({
            key: items,
            label: columns.title,
            sortable: true,
            class: 'text-center',
            thStyle: width,
          });
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({
            key: 'actions',
            class: 'text-center',
            thStyle: 'width: 5%',
          });
        }
        this.loading = false;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let tempArray2 = {};
        let excelData = [];

        for (let i = 0; i < this.excelJson.length; i++) {
          let tempArray = [];
          for (const [key, value] of Object.entries(this.excelJson[i])) {
            tempArray.push(value);
          }
          tempArray2.timestamp = tempArray[0];
          tempArray2.email = tempArray[1];
          tempArray2.date = tempArray[2];
          tempArray2.firstName = tempArray[3];
          tempArray2.lastName = tempArray[4];
          tempArray2.schoolName = tempArray[5];
          tempArray2.gradeLevel = tempArray[6];
          tempArray2.classSize = tempArray[7];
          tempArray2.one_one = tempArray[7];
          tempArray2.one_two = tempArray[9];

          excelData.push(tempArray2);
        }

        let controlExcelColumn = 0;
        // let matchRegex = /(appraisedVehiclePrice|stockNumber|appraisalNotes|sellerType|firstName)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          if (controlExcelColumn === 0) {
            this.excelJson.forEach((data) => {
              if (data['Timestamp']) {
                data['Timestamp'] = self.excelDateToJSDateTime(data['Timestamp']);
              }
              if (data['Date']) {
                data['Date'] = self.excelDateToJSDate(data['Date']);
              }
            });
            let excelArray = [];
            for (let i = 0; i <= this.excelJson.length; i++) {}
            self.$refs['file-input'].reset();
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
    excelDateToJSDateTime(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    },
    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '/' + (date_info.getMonth() + 1) + '/' + date_info.getFullYear();
    },
  },
};
</script>
